<template>
  <div id="resetPsd">
    <div class="content">
      <div class="header">
        密码快速重置<router-link to="/box/personCenter">返回用户中心</router-link>
      </div>
      <div class="box">
        <div class="box-list">
          <span>手机号码：</span>
          <font>{{ resetPsd.phone }}</font>
        </div>
        <div class="box-list">
          <span>输入验证码：</span>
          <input
            class="bl-code"
            :class="{ error: codeTxt }"
            v-model="resetPsd.code"
            type="text"
            autocomplete="new-password"
            placeholder="请输入验证码"
          />
          <a href="javascript:;" class="get-code" v-if="codeStatus" @click="getCode"
            >获取验证码</a
          >
          <a href="javascript:;" v-else>{{ second }}秒后获取</a>
        </div>
        <div class="box-list" v-if="codeTxt">
          <span></span>
          <p>{{ codeTxt }}</p>
        </div>
        <div class="box-list">
          <span>输入新密码：</span>
          <input
            class="bl-input"
            :class="{ error: pwdTxt }"
            v-model="resetPsd.pwd"
            type="password"
            autocomplete="new-password"
            placeholder="请输入新密码"
          />
        </div>
        <div class="box-list" v-if="pwdTxt">
          <span></span>
          <p>{{ pwdTxt }}</p>
        </div>
        <div class="box-list">
          <span>重复新密码：</span>
          <input
            class="bl-input"
            :class="{ error: resetPsdTxt }"
            v-model="resetPsd.resetPsd"
            type="password"
            autocomplete="new-password"
            placeholder="请确认新密码"
          />
        </div>
        <div class="box-list" v-if="resetPsdTxt">
          <span></span>
          <p>{{ resetPsdTxt }}</p>
        </div>
        <div class="box-list">
          <span></span>
          <button @click="resetPsdFn">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get_cloud_verify, send_sms } from "@/api/login";
import Yuan from "pingansec-vue-yuan";
import { reset_pwd } from "@/api/resetPsd";
export default {
  data() {
    return {
      resetPsd: {
        phone: this.$store.state.userInfo.account,
        pwd: null,
        code: null,
        resetPsd: null,
      },
      iTimer: null,
      second: 60,
      codeStatus: true,
      codeTxt: null,
      pwdTxt: null,
      resetPsdTxt: null,
    };
  },
  methods: {
    resetPsdFn() {
      if (!this.resetPsd.code) {
        this.codeTxt = "请输入验证码";
        return false;
      } else {
        this.codeTxt = null;
      }
      if (!this.resetPsd.pwd) {
        this.pwdTxt = "请输入新密码";
        return false;
      } else {
        this.$C.psdRegFn(this.resetPsd.pwd).then((res) => {
          if (res) {
            this.pwdTxt = res;
            return false;
          } else {
            this.pwdTxt = null;
            if (this.resetPsd.pwd != this.resetPsd.resetPsd) {
              this.resetPsdTxt = "请再次输入新密码";
              return false;
            } else {
              this.resetPsdTxt = null;
              reset_pwd({
                phone: this.resetPsd.phone,
                pwd: this.resetPsd.pwd,
                code: this.resetPsd.code,
              }).then((res) => {
                let { result_code, message, data } = res.data;
                if (result_code == 0) {
                  localStorage.setItem("ajt_token", data.token);
                  this.alert('密码重置成功！', "success");
                  this.$router.push({
                    path: "/",
                  });
                } else {
                  this.alert(message, "error");
                }
              });
            }
          }
        });
      }
    },
    // 60秒倒计时
    countdown() {
      this.second = 60;
      this.codeStatus = false;
      clearInterval(this.iTimer);
      this.iTimer = setInterval(() => {
        this.second--;
        if (this.second == 0) {
          clearInterval(this.iTimer);
          this.codeStatus = true;
        }
      }, 1000);
    },
    getCode() {
      this.$C.telRegFn(this.resetPsd.phone).then((phone) => {
        if (!phone) {
          get_cloud_verify().then((res) => {
            let { result_code, message, data } = res.data;
            if (result_code == 0) {
              let token = data.token;
              let yuan = new Yuan({
                token,
                completed: (img_code) => {
                  send_sms({
                    send_type: 1,
                    phone: this.resetPsd.phone,
                    img_token: token,
                    img_code,
                  }).then((sms) => {
                    let { result_code, message, data } = sms.data;
                    if (result_code == 0) {
                      this.countdown();
                    } else {
                      this.alert(message, "error");
                    }
                  });
                  yuan.hideCode();
                },
              });
              yuan.showCode();
            } else {
              this.alert(message, "error");
            }
          });
        } else {
          this.alert(phone, "error");
        }
      });
    },
    // message提示框稍微包一下
    alert(txt, type) {
      this.$message.closeAll();
      this.$message({
        message: txt,
        type: type,
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss"></style>
